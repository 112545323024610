/* eslint-disable import/prefer-default-export */
import type { GatsbyBrowser } from 'gatsby';

declare global {
  interface Window {
    SignUpFormWidget: any;
  }
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  if (location.pathname === '/' && window.SignUpFormWidget)
    window.SignUpFormWidget.main();
};
